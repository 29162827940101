import React from 'react'
import UsedEquipmentTemplate from 'templates/used-equipment-template'
import { graphql } from 'gatsby'

const UsedEquipmentCategoryTemplate = props => {
  return <UsedEquipmentTemplate {...props} />
}

export const pageQuery = graphql`
  query UsedEquipmentCategory($category: String!) {
    allMachineFinderEquipment(
      filter: { category: { title: { eq: $category } } }
      sort: { fields: [modifiedDate], order: DESC }
    ) {
      nodes {
        engineHoursMeter
        horsepower
        id
        inBargainBin
        inHUT229
        initialPrice
        isNew
        location {
          name
          state
        }
        manufacturer
        meters {
          type
          value
        }
        model
        modelYear
        modifiedDate
        price
        primaryImage {
          large
          regular
        }
        reducedPrice
        separatorHoursMeter
        slug
        stockNumber
        title
      }
    }
    allSanityPromotion(
      filter: {
        websiteEnabled: { eq: true }
        relatedUsedEquipmentCategories: { elemMatch: { title: { eq: $category } } }
        parentPromotion: { id: { eq: null } }
        type: {
          in: [
            "financing"
            "discount"
            "financing-discount"
            "cash-purchase-discount"
            "parent-promotion"
          ]
        }
      }
      sort: { order: ASC, fields: [details___term, details___rate, details___discount] }
    ) {
      nodes {
        disclaimer
        endDate
        id
        slug
        startDate
        subtitle
        title
        type
      }
    }
  }
`

export default UsedEquipmentCategoryTemplate
